<template>
<div id="app">
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="12" class="text-left"><h4>Create New Advertisement</h4></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CForm>

              <CInput
                  type="text"
                  label="Advertisement Name"
                  v-model="name"
                  placeholder="Type Name"
                  horizontal
              />

              <CInput
                  type="text"
                  label="Title"
                  v-model="title"
                  placeholder="Type Title"
                  :maxlength="maxTitleLength"
                  :description="maxTitleLengthDesc"
                  horizontal
              />

              <CTextarea
                    label="Body"
                    v-model="body"
                    placeholder="Type Body..."
                    :description="maxBodyLengthDesc"
                    @keypress="checkLimitBody"
                    @keyup="checkLimitBody"
                    horizontal
                    rows="5"
              />

              <b-row>
                <b-col sm="3">
                  <label for="input-small">AD Type</label>
                </b-col>
                <b-col sm="9">
                  <b-form-select v-model="adType" :options="optAdTypes" @change="onChangeAdTypes"></b-form-select>
                </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Limited Group</label>
                  </b-col>
                  <b-col sm="2">
                     <b-form-checkbox
                      v-model="cblimgroup"
                      @change="onModifyLimGroup"
                      name="cblimgroup"
                      value="true"
                      unchecked-value="false">
                      Yes
                     </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                     <label for="input-small" >Groups:</label>
                  </b-col>
                  <b-col sm="5">
                    <multiselect @select="toggleGroupSelected" @remove="toggleGroupUnSelect" v-model="userGroup" :disabled="!isDisabledlg" :options="optGroup" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                    <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Limited User</label>
                  </b-col>
                  <b-col sm="2">
                     <b-form-checkbox
                      v-model="cblimuser"
                      @change="onModifyLimUsr"
                      name="cblimuser"
                      value="true"
                      unchecked-value="false">
                      Yes
                     </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                     <label for="input-small" >Users:</label>
                  </b-col>
                  <b-col sm="5">
                      <multiselect @select="toggleUserSelected" @remove="toggleUserUnSelect" v-model="user" :disabled="!isDisabledlu" :options="optusers" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Phone Number" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection3" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                      </multiselect>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Limited Nationalities</label>
                  </b-col>
                  <b-col sm="2">
                     <b-form-checkbox
                      v-model="cblimnat"
                      @change="onModifyLiNat"
                      name="cblimuser"
                      value="true"
                      unchecked-value="false">
                      Yes
                     </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                     <label for="input-small" >Nationalities:</label>
                  </b-col>
                  <b-col sm="5">
                      <multiselect v-model="nations" :disabled="!isDisabledln" :options="optNations" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Nationalities" label="name" track-by="name" :preselect-first="true">
                      <template slot="selection4" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                      </multiselect>
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Appearance Order</label>
                  </b-col>
                  <b-col sm="9">
                      <CInput type="number" min="0" v-model="appearanceOrder" />
                  </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Expiry Date</label>
                  </b-col>
                  <b-col sm="9">
                      <CInput type="date" v-model="expiryDate" @input="startDateOnChange()" />
                   </b-col>
                   <!-- <b-col sm="4">
                      <vue-timepicker @change="sttimeChange" v-model="fromTime" format="HH:mm:ss"></vue-timepicker>
                   </b-col>                   -->
              </b-row><br/>

              <CTextarea
                    label="Term & Conditions"
                    v-model="tnc"
                    placeholder="Type Term & Conditions..."
                    maxlength="180"
                    description="Maximum 180 characters"
                    horizontal
                    rows="5"
              />

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Upload Image</label>
                  </b-col>
                  <b-col sm="9">
                      <input type="file" :v-model="srcFile" ref="myFile" @change="onFileChangedImg"><br/>
                      <span style="font-size:12px;">*Require (Width x Height) :  720 x 920 (Max)</span>
                  </b-col>
              </b-row><br/>

              <b-row v-if="adType == 'PROMOTION' || adType == 'NEWS'">
                  <b-col sm="3">
                     <label for="input-small" >Preview Image</label>
                  </b-col>
                  <b-col sm="9">
                      <input type="file" :v-model="previewFile" ref="myFile2" @change="onFileChangedImg2"><br/>
                      <span style="font-size:12px;">*Require (Width x Height) :  {{ previewSize }} (Max)</span>
                  </b-col>
              </b-row><br v-if="adType == 'PROMOTION' || adType == 'NEWS'"/>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div><br/>

              <CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol>
              </CRow>

              <b-row>
                  <b-col sm="3"></b-col>
                  <b-col sm="9">
                    <b-button-group>
                    <router-link to="/advertise" tag="b-button">Cancel</router-link>
                    <b-button style="width:100px;" v-on:click="actionSave()" variant="primary">Save</b-button>
                    </b-button-group>
                  </b-col>
              </b-row><br/>

           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js';
 import AdvertiseService from '@/api/AdvertiseService.js';
 import VueTimepicker from 'vue2-timepicker';
 import Multiselect from 'vue-multiselect';
 import moment from 'moment';
 import listOfNation from '@/assets/nationalities.json';

  export default {
    name: 'create_template',
    components: {
        Multiselect,
        VueTimepicker
    },
    data() { var date = new Date();
      return {
          id:0,
          isDisabledlg:false,
          isDisabledlu:false,
          isDisabledln:false,
          spinner:false,
          maxTitleLength:200,
          maxTitleLengthDesc:"Maximum 200 chars",
          maxBodyLength:"500",
          maxBodyLengthDesc:"Maximum 500 words",
          name:'',
          title:'',
          body:'',
          cblimgroup:'',
          userGroup:'',
          optGroup:[],
          optAdTypes:[],
          baseoptGroup:[],
          cblimuser:'',
          user:'',
          adType:'',
          optusers:[],
          baseUserId:[],
          basePhoneNum:[],
          cblimnat:'',
          nations:[],
          optNations :[],
          appearanceOrder:0,
          expiryDate : date.toISOString().substr(0, 10),
          tnc:'',
          srcFile:'',
          previewFile:'',
          previewSize:'680 x 240',
          selectedFile: null,
          selectedFile2: null,
          widthPreview: 680,
          heightPreview: 240,
          image:{
            size:0,
            height:0,
            width:0
          },
          image2:{
            size:0,
            height:0,
            width:0
          },
          seen:false,
          msg :'',
          color:''
        };
    },
    created () {
        this.getUserGroupList();
        this.getConfigList();
        this.fetchUsers();
    },
    methods: {

      onFileChangedImg(){
         this.selectedFile = event.target.files[0];
      },

      onFileChangedImg2(){
         this.selectedFile2 = event.target.files[0];
      },

      onModifyLimGroup(){
         if(this.cblimgroup=="true"){
              this.isDisabledlg= true; this.optGroup = this.baseoptGroup;
         } else {  this.isDisabledlg= false; this.optGroup=[]; }
      },

      onModifyLimUsr(){
        if(this.cblimuser=="true"){
              this.isDisabledlu= true;
         } else {  this.isDisabledlu= false; }
      },

      onModifyLiNat(){
        if(this.cblimnat == "true"){
              this.isDisabledln= true; this.getNationalityList();
        } else {  this.isDisabledln= false; this.optNations =[]; }
      },

      toggleGroupSelected(){
      },

      toggleGroupUnSelect(){
      },

      toggleUserUnSelect(){
      },

      toggleUserSelected(){
      },

      startDateOnChange(){
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      actionSave(){
        this.spinner = true;
        let params = {};  var validate = [];
        var listUser = []; var listGroup = [];  var listNat = [];

        if(this.cblimgroup === "true"){
            var userGroup = this.userGroup;
            for (var key in userGroup) {
               var groupId = userGroup[key].id; listGroup.push(groupId);
            }
        }

        if(this.cblimuser === "true"){
            var user = this.user;
            for (var key2 in user) {
               var phone = user[key2].name; listUser.push(phone);
            }
        }

        if(this.cblimnat === "true"){
            var nat = this.nations;
            for (var key3 in nat) {
              var natkey = nat[key3].id; listNat.push(natkey);
            }
        }

        params['allowedUserGroups'] = listGroup;
        params['allowedUsers'] = listUser;
        params['allowedNationalities'] = listNat;
        params['body'] = this.body;
        params['appearanceOrder'] = this.appearanceOrder;
        params['expiryDate'] = this.expiryDate+"T23:59:59";
        params['limitedToUserGroups'] = this.stringToBoolean(this.cblimgroup);
        params['limitedToUsers'] = this.stringToBoolean(this.cblimuser);
        params['limitedToNationalities'] = this.stringToBoolean(this.cblimnat);
        params['name'] = this.name;
        params['recordStatus'] = "ACTIVE";
        params['termsAndConditions'] = this.tnc;
        params['title'] = this.title;
        params['adType'] = this.adType;

        var checkKeys = ["body","expiryDate","name","termsAndConditions","title"];
        for (var key in params) {
          if(checkKeys.includes(key)){
            if(!params[key]) { validate.push(false); this.msg   = 'Data Not Complete!';
            } else { validate.push(true); }
          }
        }

        setTimeout( () => this.onUploadUploadImg() , 5000);
        setTimeout( () => this.finalSubmit(validate,params) , 7000);

      },

      finalSubmit(validate,params){
            if(!validate.includes(false)){   console.log(params);
                if(this.adType === 'POPUP') {
                    if(this.image.width === 720 && this.image.height <= 920 ) {
                        this.createNewAdvertise(params);   //console.log('Submit');
                    } else {
                        this.color = 'warning'; this.seen  = true;
                        this.msg  = "Failed, detected dimension " + this.image.width +" * "+ this.image.height;
                        setTimeout( () =>  this.seen=false , 5000); this.spinner = false;
                    }
                } else {
                    if (this.adType === 'NEWS') {
                        if(this.image2.width === 680 && this.image2.height <= 240 ) {
                          this.createNewAdvertise(params);   //console.log('Submit');
                        } else {
                          this.color = 'warning'; this.seen  = true;
                          this.msg  = "Failed, detected dimension " + this.image2.width +" * "+ this.image2.height;
                          setTimeout( () =>  this.seen=false , 5000); this.spinner = false;
                        }
                    } else {
                        if(this.image2.width === 680 && this.image2.height <= 240 ) {
                          this.createNewAdvertise(params);   //console.log('Submit');
                        } else {
                          this.color = 'warning'; this.seen  = true;
                          this.msg  = "Failed, detected dimension " + this.image2.width +" * "+ this.image2.height;
                          setTimeout( () =>  this.seen=false , 5000); this.spinner = false;
                        }
                    }
                }
            } else {
              this.color = 'warning'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000); this.spinner = false;
            }
      },

      clearForm(){
          this.name = '';
          this.title = '';
          this.body = '';
          this.cblimgroup = '';
          this.userGroup='';
          this.cblimuser = '';
          this.user='';
          this.cblimnat='';
          this.nations='';
          this.expiryDate = new Date().toISOString().substr(0, 10);
          this.tnc = '';
          this.srcFile = '';
          this.srcFile2 = '';
      },

      async onUploadUploadImg() {

          let file = this.$refs.myFile.files[0];
          if(!file || file.type.indexOf('image/') !== 0) return;

          this.image.size = file.size;
          let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = evt => {
              let img = new Image();
              img.onload = () => {
                this.image.width  = img.width;
                this.image.height = img.height;
              }
              img.src = evt.target.result;
            }
            reader.onerror = evt => { console.error(evt); }
            //setTimeout( () =>  this.actionSubmit() , 5000);
            if (this.adType === 'NEWS' || this.adType === 'PROMOTION')
            {
                  let file2 = this.$refs.myFile2.files[0];
                  if (!file2 || file2.type.indexOf('image/') !== 0) return;

                  this.image2.size = file2.size;
                  let reader2 = new FileReader();
                  reader2.readAsDataURL(file2);
                  reader2.onload = evt => {
                    let img2 = new Image();
                    img2.onload = () => {
                        this.image2.width = img2.width;
                        this.image2.height = img2.height;
                    }
                    img2.src = evt.target.result;
                  }
                  reader2.onerror = evt => {
                    console.error(evt);
                  }
            }
            //setTimeout( () =>  this.actionSubmit() , 5000);
      },

      async saveImage(){
            const formData = new FormData()
            formData.append('resource', this.selectedFile, this.selectedFile.name);
            try {
                const resp = await AdvertiseService.uploadAdvertiseImg(this.id,formData);   //console.log(resp);
                if(resp.statusCode==="S0000"){
                    if (this.adType === 'NEWS' || this.adType === 'PROMOTION') {
                        const formData2 = new FormData()
                        formData2.append('resource', this.selectedFile2, this.selectedFile2.name);
                        const resp2 = await AdvertiseService.uploadAdvertiseImgPreview(this.id,formData2);   //console.log(resp);
                        if(resp2.statusCode==="S0000"){
                            this.msg   = 'Create New Advertise Success';
                            this.color = 'success';  this.seen  = true;
                        } else {
                            this.msg   = !resp2.message ? 'Success create Advertise, but failed to save preview image' : resp2.message;
                            this.color = 'danger';  this.seen  = true;
                        }
                    } else {
                        this.msg   = 'Create New Advertise Success';
                        this.color = 'success';  this.seen  = true;
                    }
                    this.clearForm();
                } else {
                    this.msg   =  !resp.data.message ? "Success create Advertise but Image Failed!" : resp.data.message;
                    this.color = 'danger'; this.seen  = true;
                }
                this.spinner = false;
                setTimeout( () =>  this.seen=false , 5000);
            } catch (error) { console.log(error); }
      },

      createNewAdvertise: function(params) {
          AdvertiseService.createNewAdvertise(params).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.id = resp.payload.id;
                this.saveImage();
              } else {
                this.msg   = !resp.data.message ? "Create New Advertise Failed!" : resp.data.message;
                this.color = 'warning'; this.seen  = true; this.spinner = false;
              }

              setTimeout( () =>  this.seen=false , 5000);
           }, error => {
            console.log('error');
          });
      },

      onChangeAdTypes() {
         if(this.adType === 'NEWS') {
            this.previewSize   = '680 x 240'
            this.widthPreview  = 680
            this.heightPreview = 240
            this.maxBodyLength = 500
            this.maxBodyLengthDesc = "Maximum 500 words"
            this.maxTitleLength = 200
            this.maxTitleLengthDesc = "Maximum 200 chars"
         } else {
            this.previewSize   = '680 x 240'
            this.widthPreview  = 680
            this.heightPreview = 240
            this.maxBodyLength = 250
            this.maxBodyLengthDesc = "Maximum 250 characters"
            this.maxTitleLength = 40
            this.title = this.title.substring(0, this.maxTitleLength)
            this.maxTitleLengthDesc = "Maximum 40 chars"
            this.checkLimitBody()
         }
      },

      checkLimitBody(){
        let count = (this.adType === 'NEWS') ? this.body.split(" ").length : this.body.length
        let limitBody = this.maxBodyLength
        if(count > limitBody) {
          let restBody = limitBody - count
          console.log(restBody)
          if(this.adType === 'NEWS') {
            this.body = this.body.split(" ").slice(0, restBody).join(" ")
          } else {
            this.body = this.body.substring(0, this.maxBodyLength)
          }
        }
      },

      getNationalityList(){
          var list = listOfNation; var result = [];
          for (var key in list) {
                var name  = list[key];
                result.push({ id : key, name : name });
          }
          this.optNations = result;
      },

      getUserGroupList: function() {
        TransactService.getUserGroupList().then(resp => {  //console.log(resp);

            var items = resp; var wallets = []; var temp = [];
            for (var key in items) {
                var GroupId   = items[key].id;
                var GroupName = items[key].name;
                var GroupUId  = items[key].groupUniqueNumber;
                temp.push(GroupName+"|"+GroupUId+"|"+GroupId);
            }

            temp.sort();

            for (var key2 in temp) {
                var value = temp[key2].split("|");
                var name = value[0] +' ('+ value[1] +') ';
                var GroupId =  value[1];
                wallets.push({ id : GroupId, name : name }); name='';
            }

            this.optGroup = wallets;
            this.baseoptGroup = wallets;

        }, error => {
          this.loading = false;
        });
      },

      getConfigList: function() {
        AuthService.getConfigList().then(resp => {
          let adTypeSrc=[]; var temp = [];
          let arrAd = resp.adTypes;
          for (const item of arrAd){ temp.push(item); }
          temp.sort();
          let chosen = '';
          for (let key in temp) {
            if(key == 0) chosen = temp[key];
            adTypeSrc.push({ value: temp[key] , text: temp[key] });
          }
          this.adType = chosen;
          this.optAdTypes = adTypeSrc;
          console.log(this.optAdTypes);
        }, error => {
          console.log(error);
        });
      },

      fetchUsers: function() {
        AuthService.getUserList({page:0, size:10000}).then(resp => {

        var users = resp.payload;
        var items = []; var userId=[]; var basePhoneNum=[];

        for (var key in users) {
            var countryCode = users[key].mobileNumber.countryCode;
            var phoneNum = users[key].mobileNumber.number;
            var ccphoneNum = countryCode+phoneNum;
            items.push({ name : ccphoneNum });
            userId.push(users[key].userIdentify.userId);
            basePhoneNum.push(ccphoneNum);
        }

        //console.log(items);
        this.optusers = items;
        this.baseUserId = userId;
        this.basePhoneNum = basePhoneNum;

        }, error => {
          this.loading = false;
        });
      },

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
